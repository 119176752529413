export const dancers = [
  {
    name: "Akane Abe",
    role: "Dancer",
    fileName: "akane",
  },
  {
    name: "Maaya Agame",
    role: "Dancer",
    fileName: "maaya",
  },
  {
    name: "Davide Camorani",
    role: "Dancer & Co-Choreographer",
    fileName: "davide",
  },
  {
    name: "Carla Gonçlaves",
    role: "Dancer & Rehearsal Director",
    fileName: "carla",
  },
  {
    name: "Alana Heath",
    role: "Dancer",
    fileName: "alana",
  },
  {
    name: "Maika Klaukien",
    role: "Artistic Director",
    fileName: "maika",
  },
  {
    name: "Pauline Schwartz",
    role: "Dancer",
    fileName: "pauline",
  },
  {
    name: "Rina Wako",
    role: "Dancer",
    fileName: "rina",
  },
  {
    name: "Thomas Wilson",
    role: "Dancer & Producer",
    fileName: "thomas",
  },
]

export const collaborators = [
  {
    name: "Lynne Gentle",
    role: "Musician",
    fileName: "lynne",
  },
  {
    name: "Vera Bohl",
    role: "Composer",
    fileName: "vera",
  },
  {
    name: "Michael Haslam",
    role: "Musician",
    fileName: "michael",
  },
  {
    name: "Jonathan Samuels",
    role: "Visual Designer",
    fileName: "sam",
  },
  {
    name: "So Takao",
    role: "Musician",
    fileName: "so",
  },
]
